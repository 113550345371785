import { findIndex } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';



// ----------------------------------------------------------------------

const initialState = {
  tables: [],
};

const slice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TABLES
    getTablesSuccess(state, action) {
      state.isLoading = false;
      state.tables = action.payload;
    },

    // CREATE NEW TABLE
    createTableSuccess(state, action) {
      const newTable = action.payload;
      state.tables.push(newTable);
      state.isLoading = false;
    },

    // UPDATE TABLE
    updateTableSuccess(state, action) {
      const table = action.payload;

      const i = findIndex(state.tables, {_id: table._id});

      state.tables.splice(i, 1, table);
      state.isLoading = false;
    },

    // DELETE TABLE
    deleteTableSuccess(state, action) {
      const { id } = action.payload;
      
      state.tables = state.tables.filter((row) => row._id !== id);
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { actions } = slice;


// ----------------------------------------------------------------------

export function fetchTables() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/tables');
      dispatch(slice.actions.getTablesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createTable(table) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/tables', table);
      dispatch(slice.actions.createTableSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTable(tableId, table) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/tables/${tableId}`, table);
      dispatch(slice.actions.updateTableSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTable(table) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/tables/${table._id}`);
      dispatch(slice.actions.deleteTableSuccess({ id: table._id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import { findIndex } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';



// ----------------------------------------------------------------------

const initialState = {
  dataSets: [],
};

const slice = createSlice({
  name: 'dataSet',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DataSets
    getDataSetsSuccess(state, action) {
      state.isLoading = false;
      state.dataSets = action.payload;
    },

    // CREATE NEW TABLE
    createDataSetSuccess(state, action) {
      const newDataSet = action.payload;
      state.dataSets.push(newDataSet);
      state.isLoading = false;
    },

    // UPDATE TABLE
    updateDataSetSuccess(state, action) {
      const dataSet = action.payload;

      const i = findIndex(state.dataSets, {_id: dataSet._id});

      state.dataSets.splice(i, 1, dataSet);
      state.isLoading = false;
    },

    // DELETE TABLE
    deleteDataSetSuccess(state, action) {
      const { id } = action.payload;
      
      state.dataSets = state.dataSets.filter((row) => row._id !== id);
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { actions } = slice;


// ----------------------------------------------------------------------

export function fetchDataSets() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/dataSets');
      dispatch(slice.actions.getDataSetsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createDataSet(dataSet) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/dataSets', dataSet);
      dispatch(slice.actions.createDataSetSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateDataSet(dataSetId, dataSet) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/dataSets/${dataSetId}`, dataSet);
      dispatch(slice.actions.updateDataSetSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteDataSet(dataSet) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/dataSets/${dataSet._id}`);
      dispatch(slice.actions.deleteDataSetSuccess({ id: dataSet._id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const DashboardMain = Loadable(lazy(() => import('../pages/dashboard/DashboardMain')));

// Tables
export const TablesListPage = Loadable(lazy(() => import('../pages/dashboard/tables/TablesListPage')));
export const TablesCreatePage = Loadable(lazy(() => import('../pages/dashboard/tables/TablesCreatePage')));
export const TablesEditPage = Loadable(lazy(() => import('../pages/dashboard/tables/TablesEditPage')));
export const TablesRelationshipsPage = Loadable(lazy(() => import('../pages/dashboard/tables/TablesRelationshipsPage')));
// Data
export const DataSetNavigationPage = Loadable(lazy(() => import('../pages/dashboard/data/DataSetNavigationPage')));
export const DataSetSettingsPage = Loadable(lazy(() => import('../pages/dashboard/data/DataSetSettingsPage')));
export const DataSetDataPage = Loadable(lazy(() => import('../pages/dashboard/data/DataSetDataPage')));
export const DataSetConstantsPage = Loadable(lazy(() => import('../pages/dashboard/data/DataSetConstantsPage')));
export const DataSetListPage = Loadable(lazy(() => import('../pages/dashboard/data/DataSetListPage')));
export const DataSetEditPage = Loadable(lazy(() => import('../pages/dashboard/data/DataSetEditPage')));
export const DataSetCreatePage = Loadable(lazy(() => import('../pages/dashboard/data/DataSetCreatePage')));

// Function Sets
export const FunctionSetListPage = Loadable(lazy(() => import('../pages/dashboard/functionSets/FunctionSetListPage')));
export const FunctionSetEditPage = Loadable(lazy(() => import('../pages/dashboard/functionSets/FunctionSetEditPage')));

export const FunctionListPage = Loadable(lazy(() => import('../pages/dashboard/functionSets/functions/FunctionListPage')));
export const FunctionEditPage = Loadable(lazy(() => import('../pages/dashboard/functionSets/functions/FunctionEditPage')));
export const FunctionCreatePage = Loadable(lazy(() => import('../pages/dashboard/functionSets/functions/FunctionCreatePage')));

export const ParametersListPage = Loadable(lazy(() => import('../pages/dashboard/functionSets/parameters/ParametersListPage')));

export const VisualizationsPage = Loadable(lazy(() => import('../pages/dashboard/functionSets/visualizations/VisualizationsPage')));

// Runs
export const RunsListPage = Loadable(lazy(() => import('../pages/dashboard/runs/RunsListPage')));
export const RunsCreatePage = Loadable(lazy(() => import('../pages/dashboard/runs/RunsCreatePage')));
export const RunsEditPage = Loadable(lazy(() => import('../pages/dashboard/runs/RunsEditPage')));

// Visualizations

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

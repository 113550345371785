import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import tableReducer from './slices/tables';
import dataSetReducer from './slices/dataSets';
import functionSetReducer from './slices/functionSets';
import functionRecordsReducer from './slices/functionRecords';
import parameterReducer from './slices/parameters';
import dataReducer from './slices/data';
import runReducer from './slices/runs';
import associationsReducer from './slices/associations';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  parameters: parameterReducer,
  data: dataReducer,
  dataSets: dataSetReducer,
  functionRecords: functionRecordsReducer,
  functionSets: functionSetReducer,
  tables: tableReducer,
  runs: runReducer,
  associations: associationsReducer,
});

export default rootReducer;

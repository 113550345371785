import { findIndex } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';



// ----------------------------------------------------------------------

const initialState = {
  parameters: [],
};

const slice = createSlice({
  name: 'parameters',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONSTANTS
    getParametersSuccess(state, action) {
      state.isLoading = false;
      state.parameters = action.payload;
    },

    // CREATE NEW CONSTANT
    createParameterSuccess(state, action) {
      const newParameter = action.payload;
      state.parameters.push(newParameter);
      state.isLoading = false;
    },

    // UPDATE CONSTANT
    updateParameterSuccess(state, action) {
      const parameter = action.payload;

      const i = findIndex(state.parameters, {_id: parameter._id});

      state.parameters.splice(i, 1, parameter);
      state.isLoading = false;
    },

    // DELETE CONSTANT
    deleteParameterSuccess(state, action) {
      const { id } = action.payload;
      
      state.parameters = state.parameters.filter((row) => row._id !== id);
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { actions } = slice;


// ----------------------------------------------------------------------

export function fetchParameters(dataSetId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/parameters/${dataSetId}`);
      dispatch(slice.actions.getParametersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createParameter(dataSetId, parameter) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/parameters/${dataSetId}`, parameter);
      dispatch(slice.actions.createParameterSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateParameter(dataSetId, parameterId, parameter) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/parameters/${dataSetId}/${parameterId}`, parameter);
      dispatch(slice.actions.updateParameterSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteParameter(dataSetId, parameter) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/parameters/${dataSetId}/${parameter._id}`);
      dispatch(slice.actions.deleteParameterSuccess({ id: parameter._id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

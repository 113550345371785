// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  tables: icon('ic_folder'),
  data: icon('ic_file'),
  functions: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
 {
    subheader: 'general',
    items: [
      // Tables
      {
        title: 'Tables',
        path: PATH_DASHBOARD.tables.root,
        icon: ICONS.tables,
      },
      // Data
      {
        title: 'Data',
        path: PATH_DASHBOARD.data.root,
        icon: ICONS.data,
      },
      {
        title: 'Functions', 
        path: PATH_DASHBOARD.functionSets.root,
        icon: ICONS.functions,
      },
      {
        title: 'Runs',
        path: PATH_DASHBOARD.runs.root,
        icon: ICONS.dashboard
      },
    ],
  },
];

export default navConfig;

// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboardMain: path(ROOTS_DASHBOARD, '/dashboard-main'),
  tables: {
    root: path(ROOTS_DASHBOARD, '/tables'),
    list: path(ROOTS_DASHBOARD, '/tables/list'),
    new: path(ROOTS_DASHBOARD, '/tables/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/tables/${id}/edit`),
    relationships: path(ROOTS_DASHBOARD, '/tables/relationships'),
  },
  data: {
    root: path(ROOTS_DASHBOARD, '/data'),
    list: path(ROOTS_DASHBOARD, '/data/list'),
    new: path(ROOTS_DASHBOARD, '/data/new'),
    edit: {
      root: (id) => path(ROOTS_DASHBOARD, `/data/${id}/edit`),
      settings: (id) => path(ROOTS_DASHBOARD, `/data/${id}/edit/settings`),
      data: (id) => path(ROOTS_DASHBOARD, `/data/${id}/edit/data`),
      constants: (id) => path(ROOTS_DASHBOARD, `/data/${id}/edit/constants`),
    }
  },
  functionSets: {
    root: path(ROOTS_DASHBOARD, '/function-sets'),
    list: path(ROOTS_DASHBOARD, '/function-sets/list'),
    edit: {
      root: (id) => path(ROOTS_DASHBOARD, `/function-sets/${id}/edit`),
      functions: {
        root: (id) => path(ROOTS_DASHBOARD, `/function-sets/${id}/edit/functions`),
        edit: (functionSetId, functionId) => path(ROOTS_DASHBOARD, `/function-sets/${functionSetId}/edit/functions/${functionId}/edit`),
        new: (functionSetId) => path(ROOTS_DASHBOARD, `/function-sets/${functionSetId}/edit/functions/new`),
      },
      parameters: (id) => path(ROOTS_DASHBOARD, `/function-sets/${id}/edit/parameters`),
      visualizations: (id) => path(ROOTS_DASHBOARD, `/function-sets/${id}/edit/visualizations`),
    }
  },
  runs: {
    root: path(ROOTS_DASHBOARD, '/runs'),
    list: path(ROOTS_DASHBOARD, '/runs/list'),
    new: path(ROOTS_DASHBOARD, '/runs/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/runs/${id}/edit`),
  },
  visualizations: path(ROOTS_DASHBOARD, '/visualizations'),
};

import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  DashboardMain,
  // Tables
  TablesListPage,
  TablesEditPage,
  TablesCreatePage,
  TablesRelationshipsPage,
  // Data
  DataSetSettingsPage,
  DataSetDataPage,
  DataSetConstantsPage,
  DataSetListPage,
  DataSetEditPage,
  DataSetCreatePage,
  // Functions
  FunctionSetEditPage,
  FunctionSetListPage,
  FunctionListPage,
  FunctionCreatePage,
  FunctionEditPage,
  ParametersListPage,
  VisualizationsPage,
  // Runs
  RunsListPage,
  RunsCreatePage,
  RunsEditPage,
  // Login
  LoginPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard-main', element: <DashboardMain /> },
        {
          path: 'tables',
          children: [
            { element: <Navigate to="/dashboard/tables/list" replace />, index: true },
            { path: 'list', element: <TablesListPage /> },
            { path: 'new', element: <TablesCreatePage /> },
            { path: ':id/edit', element: <TablesEditPage /> },
            { path: 'relationships', element: <TablesRelationshipsPage /> },
          ],
        },
        { 
          path: 'data', 
          children: [
            { element: <Navigate to="/dashboard/data/list" replace />, index: true },
            { path: 'list', element: <DataSetListPage /> },
            { path: 'new', element: <DataSetCreatePage /> },
            { 
              path: ':dataSetId/edit', 
              element: <DataSetEditPage />,
              children: [
                { path: 'settings', element: <DataSetSettingsPage /> },
                { path: 'data', element: <DataSetDataPage /> },
                { path: 'constants', element: <DataSetConstantsPage /> },
              ],
            },
          ],
        },
        { 
          path: 'function-sets',
          children: [
            { element: <Navigate to="/dashboard/function-sets/list" replace />, index: true },
            { path: 'list', element: <FunctionSetListPage /> },
            { path: ':id/edit', 
              element: <FunctionSetEditPage />,
              children: [
                { path: 'functions', element: <FunctionListPage /> },
                { path: 'functions/new', element: <FunctionCreatePage /> },
                { path: 'functions/:functionId/edit', element: <FunctionEditPage /> },
                { path: 'parameters', element: <ParametersListPage /> },
                { path: 'visualizations', element: <VisualizationsPage /> },
              ],
            },
          ],
        },
        {
          path: 'runs',
          children: [
            { element: <Navigate to="/dashboard/runs/list" replace />, index: true },
            { path: 'list', element: <RunsListPage /> },
            { path: 'new', element: <RunsCreatePage /> },
            { path: ':id/edit', element: <RunsEditPage /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

import { findIndex } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';



// ----------------------------------------------------------------------

const initialState = {
  runs: [],
};

const slice = createSlice({
  name: 'run',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TABLES
    getRunsSuccess(state, action) {
      state.isLoading = false;
      state.runs = action.payload;
    },

    // CREATE NEW TABLE
    createRunSuccess(state, action) {
      const newRun = action.payload;
      state.runs.push(newRun);
      state.isLoading = false;
    },

    // UPDATE TABLE
    updateRunSuccess(state, action) {
      const run = action.payload;

      const i = findIndex(state.runs, {_id: run._id});

      state.runs.splice(i, 1, run);
      state.isLoading = false;
    },

    // DELETE TABLE
    deleteRunSuccess(state, action) {
      const { id } = action.payload;
      
      state.runs = state.runs.filter((row) => row._id !== id);
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { actions } = slice;


// ----------------------------------------------------------------------

export function fetchRuns() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/runs');
      dispatch(slice.actions.getRunsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createRun(run) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/runs', run);
      dispatch(slice.actions.createRunSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateRun(runId, run) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/runs/${runId}`, run);
      dispatch(slice.actions.updateRunSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteRun(run) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/runs/${run._id}`);
      dispatch(slice.actions.deleteRunSuccess({ id: run._id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

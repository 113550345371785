import { add, subDays } from 'date-fns';
//
import _mock from '../_mock';
import { randomInArray } from '../utils';

// ----------------------------------------------------------------------

export const _tables = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.text.name(index),
  description: _mock.text.description(index),
  dateCreated: subDays(new Date(), index),
  numberOfColumns: randomInArray([3, 4, 5, 6, 7, 8, 9, 10]),
  currentSize: randomInArray([3, 4, 5, 6, 7, 8, 9, 10]),
  columns: [...Array(3)].map((__, itemIndex) => ({
    id: _mock.id(itemIndex),
    name: _mock.text.name(itemIndex),
    description: _mock.text.description(itemIndex),
    type: randomInArray([
      'String',
      'Number',
      'Boolean',
      'List',
    ]),
  })),
}));
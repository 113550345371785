// i18n
import './locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// MUI License
import { LicenseInfo } from '@mui/x-license-pro';

// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// redux
import { Provider as ReduxProvider } from 'react-redux';
// routes
import Router from './routes';
// redux
import { store } from './store/store';
import { fetchTables } from './store/slices/tables';
// config
import { MUI_LICENSE_KEY } from './config-global';
// theme
import ThemeProvider from './theme';

// locales
import ThemeLocalization from './locales';
// components
import SnackbarProvider from './components/snackbar';
import { ThemeSettings, SettingsProvider } from './components/settings';
import { MotionLazyContainer } from './components/animate';
import ScrollToTop from './components/scroll-to-top';

// Check our docs
// https://docs.minimals.cc/authentication/js-version

// import { AuthProvider } from './auth/Auth0Context';
import { AuthProvider } from './auth/JwtContext';
import { fetchDataSets } from './store/slices/dataSets';
import { fetchFunctionSets } from './store/slices/functionSets';
import { fetchRuns } from './store/slices/runs';

// ----------------------------------------------------------------------

store.dispatch(fetchTables());
store.dispatch(fetchDataSets());
store.dispatch(fetchFunctionSets());
store.dispatch(fetchRuns());

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY)

export default function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <SettingsProvider>
            <BrowserRouter>
              <ScrollToTop />
              <MotionLazyContainer>
                <ThemeProvider>
                  <ThemeSettings>
                    <ThemeLocalization>
                      <SnackbarProvider>
                        <Router />
                      </SnackbarProvider>
                    </ThemeLocalization>
                  </ThemeSettings>
                </ThemeProvider>
              </MotionLazyContainer>
            </BrowserRouter>
          </SettingsProvider>
        </ReduxProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}

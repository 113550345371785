import { findIndex } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';



// ----------------------------------------------------------------------

const initialState = {
  functionSets: [],
};

const slice = createSlice({
  name: 'functionSet',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET FunctionSets
    getFunctionSetsSuccess(state, action) {
      state.isLoading = false;
      state.functionSets = action.payload;
    },

    // CREATE NEW TABLE
    createFunctionSetSuccess(state, action) {
      const newFunctionSet = action.payload;
      state.functionSets.push(newFunctionSet);
      state.isLoading = false;
    },

    // UPDATE TABLE
    updateFunctionSetSuccess(state, action) {
      const functionSet = action.payload;

      const i = findIndex(state.functionSets, {_id: functionSet._id});

      state.functionSets.splice(i, 1, functionSet);
      state.isLoading = false;
    },

    // DELETE TABLE
    deleteFunctionSetSuccess(state, action) {
      const { id } = action.payload;
      
      state.functionSets = state.functionSets.filter((row) => row._id !== id);
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { actions } = slice;


// ----------------------------------------------------------------------

export function fetchFunctionSets() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/functionSets');
      dispatch(slice.actions.getFunctionSetsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createFunctionSet(functionSet) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/functionSets', functionSet);
      dispatch(slice.actions.createFunctionSetSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateFunctionSet(functionSetId, functionSet) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/functionSets/${functionSetId}`, functionSet);
      dispatch(slice.actions.updateFunctionSetSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteFunctionSet(functionSet) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/functionSets/${functionSet._id}`);
      dispatch(slice.actions.deleteFunctionSetSuccess({ id: functionSet._id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import { findIndex } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';



// ----------------------------------------------------------------------

const initialState = {
  functionRecords: [],
};

const slice = createSlice({
  name: 'functionRecords',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      console.log('startLoading');
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      console.log('hasError');
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Functions
    getFunctionsSuccess(state, action) {
      state.isLoading = false;
      state.functionRecords = action.payload;
    },

    // CREATE NEW TABLE
    createFunctionSuccess(state, action) {
      const newFunction = action.payload;
      state.functionRecords.push(newFunction);
      state.isLoading = false;
    },

    // UPDATE TABLE
    updateFunctionSuccess(state, action) {
      const functionRecord = action.payload;

      const i = findIndex(state.functionRecords, {_id: functionRecord._id});

      state.functionRecords.splice(i, 1, functionRecord);
      state.isLoading = false;
    },

    // DELETE TABLE
    deleteFunctionSuccess(state, action) {
      const { id } = action.payload;
      
      state.functionRecords = state.functions.filter((row) => row._id !== id);
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { actions } = slice;


// ----------------------------------------------------------------------

export function fetchFunctions(functionSetId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/functionRecords/${functionSetId}`);
      dispatch(slice.actions.getFunctionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createFunction(functionSetId, functionRecord) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/functionRecords/${functionSetId}`, functionRecord);
      dispatch(slice.actions.createFunctionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateFunction(functionSetId, functionRecordId, functionRecord) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/functionRecords/${functionSetId}/${functionRecordId}`, functionRecord);
      dispatch(slice.actions.updateFunctionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteFunction(functionSetId, functionRecord) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/functionRecords/${functionSetId}/${functionRecord._id}`);
      dispatch(slice.actions.deleteFunctionSuccess({ id: functionRecord._id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import { findIndex } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';



// ----------------------------------------------------------------------

const initialState = {
  data: [],
};

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET RECORDS
    getRecordsSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },

    // CREATE NEW RECORD
    createRecordSuccess(state, action) {
      const newRecord = action.payload;
      state.data.push(newRecord);
      state.isLoading = false;
    },

    // UPDATE RECORD
    updateRecordSuccess(state, action) {
      const record = action.payload;

      const i = findIndex(state.data, {_id: record._id});

      state.data.splice(i, 1, record);
      state.isLoading = false;
    },

    // DELETE RECORD
    deleteRecordSuccess(state, action) {
      const { id } = action.payload;
      
      state.data = state.data.filter((row) => row._id !== id);
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { actions } = slice;


// ----------------------------------------------------------------------

export function fetchRecords(dataSetId, tableId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/data/${dataSetId}/${tableId}`);
      dispatch(slice.actions.getRecordsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createRecord(dataSetId, tableId, record) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/data/${dataSetId}/${tableId}`, record);
      dispatch(slice.actions.createRecordSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateRecord(dataSetId, tableId, recordId, record) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/data/${dataSetId}/${tableId}/${recordId}`, record);
      dispatch(slice.actions.updateRecordSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteRecord(dataSetId, tableId, recordId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/data/${dataSetId}/${tableId}/${recordId}`);
      dispatch(slice.actions.deleteRecordSuccess({ id: recordId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
